@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{

                         

}

/* src/fonts/YourCustomFont.css */
body{
  overflow-x: hidden !important;  
  font-family: 'CustomFont', sans-serif !important;

}
/* src/App.css or src/index.css */
@font-face {
  font-family: 'CustomFont';
  src: url('./Fonts/MyUglyHandwriting-Regular.otf') format('truetype');

}
@font-face {
  font-family: 'CustomFont2';
  src: url('./Fonts/droid-serif.regular.ttf') format('truetype');

}
body {

}

.bg {


  background-repeat: no-repeat !important;
  background-size: cover !important;
    background: #00000091 url('./rain.gif') ;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: center !important;
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    z-index: 1111;
  }
  .pageCon {
    background: rgb(87,87,87);
    background: linear-gradient(0deg, rgb(255, 119, 0) 0%, rgba(255, 255, 255, 0.908) 46%,  rgba(255, 255, 255, 0.99) 86%);
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    overflow: hidden;
    right: 0;
    top: 0;
  }

  .title {
    font-size: 45px;
    color: #fff;
    text-align: center;
    text-stroke: 2px #000; /* Set the border color and width */
    -webkit-text-stroke: 2px #dd8c00; /* For webkit browsers like Chrome and Safari */
  }
  
  @media screen and (min-width: 1000px) {
    .title {
      font-size: 103px;
      text-align: start;
    }
  }
  
  .footer_title{
    font-size: 40px;
    color: #000000;
    
  }
  @media screen and (min-width: 1000px){
    .footer_title{
      font-size: 70px;
      color: #000000;
    }
  }
  .navbartop{
    background-color: #fcfcfc00;
    border-radius: 0px 0px 100px 100px;
    border: 1px solid #ffffff;
  }

  .backofdesktop{
    background-repeat: no-repeat !important;
    background-size: cover !important;
   
      background-repeat: no-repeat!important;
      background-size: cover!important;
      height: 100%;
     background-color: #ffffe3;
      background-position: center !important;
  }
.footer{
  background-color:#0f1633;
}
  .centeritall{
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .end{
    text-align: end;
 
    justify-content: end;
    display: flex;
  }

  .top_logo{
    width: 50%;
  }
  @media screen and (min-width: 1000px){
    .top_logo{
             width: 60%;
    }
  }
  

.embrace{
  color: #000000;
  font-weight: 700;
  font-size: 11px;
}
@media screen and (min-width: 1000px){
  .embrace{
    color: #000000;
    font-weight: 700;
    font-size: 16px;
  }
  
}




.embraces{
  color: #000000;
  font-weight: 600;
  font-size: 20px;
}













.startfrom{
  justify-content: start;
  align-items: start;
  text-align: start;

}
.aboutimg{
  width: 40%;
}
.about{
  font-size: 14px;
  font-weight: 400;
}
@media screen and (min-width: 1000px){
  .about{
    font-size: 20px;
    font-weight: 400;
  }
}
.centerps{
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  background-color: #fff;
  border-radius: 10px;
  border: 2px solid #000000;

}


.ps{
  font-size: 25px;
  
}



.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #ffffff23 !important;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.5rem;
  outline: 0;
}







































































:root {
  --glow-color: hsla(0, 0%, 100%, 0.882);
}




.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.25em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
 background-color: #000000;
  perspective: 2em;
  font-family: Courier New,Courier,monospace;
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(3, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
  }
}




.dextop{
  position: relative;
  top: 8px;
}






.bgst{
  background-repeat: no-repeat !important;
  background-size: cover !important;
    background: #00000091 url('./was.webp') ;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    height: 100%;
   background-color: #000000;
    background-position: center !important;
}



.bgnavt {
  background-color: #ffffff;
  color: #dd0000;
  text-decoration: underline !important;
}


 .bgsts{
  background-color: #fff !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;

    background-repeat: no-repeat!important;
    background-size: cover!important;
    height: 100%;

    background-position: center !important;

 }


 
 .titlepl {
  font-size: 20px;
  color: #000000;
  text-align: center;
  
  
}

@media screen and (min-width: 1000px) {
  .titlepl {
    font-size: 27px;

      text-align: center;
  }
}


.navbartops{
  background-color: #0f1633;
height: 100vh;
overflow-y: hidden;

}

.navbartopsm{
  background-color: #626159;
height: 100%;

}

.startlogo{
  width: 80%;
}

@media screen and (min-width: 1000px) {
  .startlogo{
    width: 30%;
  }
}


.enterlnd{
  font-size: 70px;
  color: #ffebc6;
  cursor: pointer;
}

@media screen and (min-width: 1000px) {
  .enterlnd{
    font-size: 70px;
    color: #ffebc6;
  }
}



























.arrow {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: rotate(180deg);
  cursor: pointer;
}

.arrow span {
  display: block;
  width: 1.5vw;
  height: 1.5vw;
  border-bottom: 5px solid white;
  border-right: 5px solid white;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
      opacity: 0;
      transform: rotate(45deg) translate(-20px, -20px);
  }
  50% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      transform: rotate(45deg) translate(20px, 20px);
  }
}



.bgca{
  background-color: #0f1633;
  border-radius: 10px;
  text-align: center;
  padding: 20px;
  color: #fff;
  border: 6px solid #626159;
  font-size: 12px;
}

@media screen and (min-width: 1000px) {
  .bgca{
    background-color: #0f1633;
    border-radius: 10px;
    text-align: center;
    padding: 20px;
    color: #fff;
    border: 6px solid #626159;
    font-size: 20px;
  }
  
}


.you_are_not_an_autistic{
  background-color: #fdf5e2;
  border: 12px solid #0f1633;
  border-radius: 1;
}

.head_you_are_not_an_autistic{
  color: #000 !important;
  font-size: 70px;
  position: relative;
  left: 20px;
  text-align: center;

}


.font_top_nav{
  font-size: 40px;
  background-color: #0f1633;
  font-weight: 600;
  color: #fdf5e2 !important;
  text-decoration: none !important;
}

.DAO{
  font-family: 'CustomFont2';
  font-size: 110px !important;
  color: #0044f3 !important;
  font-weight: 600 !important;
  text-shadow: #94afec 2px 5px;
}
@media screen and (min-width: 1000px) {
  .DAO{
    font-family: 'CustomFont2';
    font-size: 180px !important;
    color: #0044f3 !important;
    font-weight: 600 !important;
    text-shadow: #94afec 2px 5px;
  }
  
}

.DAOs{

  font-size: 120px !important;
  color: #000000 !important;
  font-weight: 500 !important;

}
@media screen and (min-width: 1000px) {
  .DAOs{

    font-size: 180px !important;
    color: #000000 !important;
    font-weight: 500 !important;
  
  }
  
}

.DAOl{
  font-family: 'CustomFont2';
  font-size: 30px !important;
  text-align: center;
  color: #0044f3 !important;
  font-weight: 600 !important;
  text-shadow: #94afec 2px 5px;
}
@media screen and (min-width: 1000px) {
  .DAOl{
    font-family: 'CustomFont2';
    font-size: 70px !important;
    text-align: center;
    color: #0044f3 !important;
    font-weight: 600 !important;
    text-shadow: #94afec 2px 5px;
  }
  
}

.dah{
  font-size: 30px;
  text-align: center;
}



@media screen and (min-width: 1000px) {
  .dah{
    font-size: 46px;
  }
  
  
}

.DAOls{
  font-family: 'CustomFont2';
  font-size: 40px !important;
  text-align: center;
  color: #0044f3 !important;
  font-weight: 600 !important;
  text-shadow: #94afec 2px 5px;
}
@media screen and (min-width: 1000px) {
  .DAOls{
    font-family: 'CustomFont2';
    font-size: 50px !important;
    text-align: center;
    color: #0044f3 !important;
    font-weight: 600 !important;
    text-shadow: #94afec 2px 5px;
  }
  
}



.rota1 {
  transform: rotate(10deg);
  text-align: center !important;
}

.rota2 {
  transform: rotate(4deg);
  text-align: center !important;
}
.rota3 {
  transform: rotate(-4deg);
  text-align: center !important;
}






.dahf{
  font-size: 70px;
  text-align: center;
}



@media screen and (min-width: 1000px) {
  .dahf{
    font-size: 106px;
  }
  
  
}

.ctos{
  width: 100%;
}


@media screen and (min-width: 1000px) {
  .ctos{
width: 50%;
  }
  
  
}



@keyframes flash {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

@keyframes popIn {
  0% { transform: scale(0); opacity: 0; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes popOut {
  0% { transform: scale(1); opacity: 1; }
  100% { transform: scale(0); opacity: 0; }
}

.no_aus_meme {
  width: 100%;
  border-radius: 20px;
  transition: all 0.3s ease; /* Optional: smooth transition for other properties */
  animation: popIn 0.3s forwards; /* Initial pop-in effect */
}

.no_aus_meme:hover {
  animation: flash 0.5s infinite, popOut 0.3s forwards; /* Flash and pop-out effects on hover */
}


























































.bgp{
  background-color: #ffebc6;
  height: 130vh;
}
/* General styles */
.containers {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 15px;
font-weight: 900;
  padding: 10px;

}
@media screen and (min-width: 1000px){
  .containers {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 40px;
  font-weight: 900;
    padding: 10px;
  
  }
}
h2 {
  margin-bottom: 20px;
}

input[type="file"] {
  margin-bottom: 20px;
  padding: 10px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  background-color: #fff;
}

.error {
  color: red;
  margin-top: 10px;
}

.controls {
  margin-bottom: 20px;
}

.controls label {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.controls input {
  margin-left: 10px;
}

.preview {
  margin-top: 20px;
  text-align: center;
}

.dao-frame {
  position: relative;
  width: 100%;
  max-width: 400px;
  height: auto;
  border: 2px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.dao-img {

  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

.uploaded-img {
  position: absolute;
  object-fit: contain;
  z-index: 2;
  cursor: grab;
}

.uploaded-img:active {
  cursor: grabbing;
}

.download-btn {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #28a745;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.download-btn:hover {
  background-color: #218838;
}

/* Media Queries for responsiveness */
@media (max-width: 768px) {
  .controls label {
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  }

  .controls input {
    margin-left: 0;
    margin-top: 10px;
  }

  .preview h3 {
    font-size: 18px;
  }

  .download-btn {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  h2 {
    font-size: 18px;
    text-align: center;
  }

  input[type="file"] {
    padding: 8px;
  }

  .dao-frame {
    max-width: 300px;
  }

  .uploaded-img {
    width: 100px;
    height: 100px;
  }

  .download-btn {
    font-size: 12px;
    padding: 6px 12px;
  }
}
